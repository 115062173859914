<template>
  <div>
     <b-row v-if="servicesList.length > 0">
      <b-col
        cols="12"
        md="6"
        lg="3"
        v-for="(item, index) in activeListData"
        :key="index"
      >
        <serviceCard :item="item" />
      </b-col>
      <b-col cols="12" class="d-flex justify-content-center">
        <EKPagination :items="servicesList" v-model="activeListData" />
      </b-col>
    </b-row>
    <div
      v-else
      style="height: 65vh"
      class="d-flex align-items-center justify-content-center"
    >
      <h3>لايوجد خدمات</h3>
    </div>
  </div>
</template>
<script>
import EKPagination from "@Ekcore/components/EK-pagination";
import serviceCard from "./components/service-card.vue";
import { mapActions, mapGetters } from "vuex";
import { handleDashLangChange } from '@/libs/event-bus';
export default {
  components: {
    EKPagination,
    serviceCard,
  },
  data: () => ({
    activeListData: [],
  }),
  created() {
     this.fetchServices();
     handleDashLangChange(this.fetchServices);
  },
  computed: {
    ...mapGetters(["servicesList"]),
  },
  methods: {
    ...mapActions(["fetchServices"]),
  },
  beforeDestroy() {
    this.$store.commit("Reset_Search_Dto");
  },
};
</script>
